import react, {useState} from "react";
import './index.scss'
import emailjs from 'emailjs-com';
import axios from "axios";

const LogoFooter = require('../../img/logofooter.png')
const Icono = require('../../img/icono.png')
const Home = () =>{


    const[text,setText] = useState('ELIJE LAS ZONAS DE TU INTERES ')

    const frmContact ={
        reply_to:'vladi_ortega@hotmail.com', 
        name:'',
        telefono:'', 
        email:'',     
        zona:text, 
    }
    const [contact,setContact] = useState(frmContact);
    const handleChange = e => { 
		const {name,value} = e.target;
		setContact({...contact,[name]:value}); 
   };

    const handleSubmit = e =>{
        alert("entro")
	    e.preventDefault();
     
		emailjs.send('default_service','template_ammjr2i', contact,'akEUA8UHaQ1B4AlAf')
		.then((response) => {
				   console.log('SUCCESS!', response.status, response.text);
				   setContact(frmContact);
                   window.location.reload()
                    setTimeout(function(){
                        window.location.reload()
                    }, 1000);
           
		}, (err) => {
				   console.log('FAILED...', err);
		});
   }



   function EnviarFormulario(){

 
    if(contact.name ===''){
        alert("Debe de ingresar un nombre !")
    }else if(contact.telefono ===''){
        alert("Debe de ingresar un Telefono !")
    }else if(contact.email ===''){
        alert("Debe de ingresar un Correo !")
    }else if(contact.zona ===''){
        alert("Debe de ingresar una Zona !")
    }else{
        /*
        const datos = { 
            email: contact.email,
            firstName: contact.name,
            phone: contact.telefono,
            zona: contact.zona,
        };*/
        /*
        const headers = { 
            'Api-Token': 'c95cdd96d4e0f990c4e706f0dc519789031fd7dcb4a679d1c14bc3c647018e84592c1904',
            'Content-Type': 'application/json'
        };

        axios.post('https://mxnycorp.api-us1.com/api/3/contacts', datos, { headers })
            .then(response => console.log(response));
    */
    /*
            const userData = {
                email: contact.email,
                firstName: contact.name,
                phone: contact.telefono,
                zona: contact.zona,
              };
              axios.post("https://mxnycorp.api-us1.com/api/3/contacts", userData).then((response) => {
                console.log(response.status);
                console.log(response.data.token);
              });
    */

       


              /*
              const data = {
                email: contact.email,
                firstName: contact.name,
                phone: contact.telefono,
                zona: contact.zona,
              };

              axios.post('https://mxnycorp.api-us1.com/api/3/contacts', {"contact":data}, {
                headers: {
                'Api-Token': 'c95cdd96d4e0f990c4e706f0dc519789031fd7dcb4a679d1c14bc3c647018e84592c1904',
                'Content-Type': 'application/json '
                }
              }
            )*/






            const options = {
                method: 'POST',
                url: 'https://mxnycorp.api-us1.com/api/3/contacts',
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                  'Api-Token': 'c95cdd96d4e0f990c4e706f0dc519789031fd7dcb4a679d1c14bc3c647018e84592c1904'
                },
                data: {
                  contact: {email: 'test2@gmail.com', firstName: 'test', lastName: 'test', phone: '557565'}
                }
              };
              
              axios
                .request(options)
                .then(function (response) {
                  console.log(response.data);
                })
                .catch(function (error) {
                  console.error(error);
                });

    }



/*
    const data = { title: 'React POST Request Example' };
    const headers = { 
        'Api-Token': 'c95cdd96d4e0f990c4e706f0dc519789031fd7dcb4a679d1c14bc3c647018e84592c1904'
    };
    axios.post('https://reqres.in/api/articles', data, { headers })
        .then(response => this.setState({ articleId: response.data.id }));
   */
    }




    function changeText(id){
        if(id===1)setText('1 - LINEAS DE EXPRESIÓN')
        if(id===2)setText('2 - MANCHAS')
        if(id===3)setText('3 - OJERAS')
        if(id===4)setText('4 - FOXY EYES')
        if(id===5)setText('5 - PERFILAMIENTO MANDIBULAR')
        if(id===6)setText('6 - RINOMODELACIÓN')
        if(id===7)setText('7 - RELLENO DE LABIOS')
    }


    return(
        <div className="generalContainerLanding">
            <section className="sectionOne">
                <div className="header">
                    <div className="footersectionOne">
                        <label className="texone">
                            Hoy date el lujo de
                            cuidarte con Resvera
                        </label>
                        <label  className="textwo">
                            Tratamientos personalizado más 
                            allá del bienestar.
                        </label>
                    </div>
                    <div className="footersectionTwo">
                        <img src={Icono}></img>
                    </div>
                </div>
                <div className="pointsSelection">
                    <div className="pointOne" onClick={()=> changeText(1)}>1</div>
                    <div className="pointTwo" onClick={()=> changeText(2)}>2</div>
                    <div className="pointThree" onClick={()=> changeText(3)}>3</div>
                    <div className="pointFour" onClick={()=> changeText(4)}>4</div>
                    <div className="pointFive" onClick={()=> changeText(5)}>5</div>
                    <div className="pointSix" onClick={()=> changeText(6)}>6</div>
                    <div className="pointSeven" onClick={()=> changeText(7)}>7</div>
                </div>
                <div className="optionselected">
                    {text}
                </div>
            </section>
            
            <section className="sectionCard">
                Recibe $1,200 en tu primer tratamiento facial
            </section>

            <section className="sectionForm">
                <form >
                    <div className="agendacita">
                        <hr/>
                        <label>Agenda tu cita aquí</label>
                        <hr/>
                    </div>

                   


                    <div>
                        <label style={{fontWeight:600, fontStyle: "oblique", fontSize:"1.1rem",textAlign:"center",letterSpacing:"0.05rem" }}> Conócenos y haz de tu piel tu reflejo.</label>
                    </div>

                    <div>
                        <input type="text"  value={contact.name}  name="name" onChange={handleChange} >
                        </input>
                        <label>
                            NOMBRE
                        </label>
                    </div>


                    <div>
                        <input type="text"  value={contact.telefono}  name="telefono" onChange={handleChange}/>
                        <label>
                            TELÉFONO
                        </label>
                    </div>

                    <div>
                        <input type="text"  value={contact.email}  name="email" onChange={handleChange} />
                        <label>
                            CORREO ELECTRÓNICO
                        </label>
                    </div>

                    <div>
                        <input type="text" value={text} name="zona" />
                        <label>
                           ZONA ELEGIDA
                        </label>
                    </div>




                </form>
            </section>
        
            <section className="giftcard">
                <button onClick={()=> EnviarFormulario()} >
                    Quiero mi Giftcard
                </button>
            </section>
            <section className="footer">
                <div>
                <label style={{fontStyle:"oblique"}}>Visita nuestro Wellness Center Skin Care Clinic & Spa </label>
                    <label  ><a href="" style={{color:"#FFF"}}>Arquímedes 198, Polanco, Miguel Hidalgo, CDMX.</a></label>
                </div>
                <div>
                    <img src={LogoFooter}></img>
                </div>
            </section>
        </div>
    )
}

export default Home;